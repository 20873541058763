body {
	margin: 0;
	padding: 0;
	font-family: 'Source Sans Pro', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Muli', sans-serif;
}

.page-bg {
	padding: 20px;
	background: #ffffff;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
	border-radius: 5px;
}
.widget {
	padding: 20px;
	background: #ffffff;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.widget.quick-links a {
	color: var(--dark) !important;
	transition: all 0.2s ease-in-out;
}
.widget.quick-links a:hover {
	color: var(--green) !important;
}
:root {
	--green: #159570;
	--green-dark: #0f805f;
	--grey-1: #f6f9fc;
	--grey-2: #ddd;
	--grey-3: #aab7c4;
	--grey-4: #8898aa;
	--purple1: #2b2c50;
	--dark: #191935;
	--placeholder: #edf0f3;
}

img {
	max-width: 100%;
	height: auto;
}
.page-padding a {
	color: var(--green);
}
.page-padding a:hover {
	color: #191935;
}
body i.grey.icon {
	color: var(--grey-3) !important;
}
body .ui.grey.button,
body .ui.grey.buttons .button {
	background-color: var(--grey-3) !important;
}
body .ui.grey.button:hover,
body .ui.grey.buttons .button:hover {
	background-color: var(--grey-4) !important;
}
body .ui.basic.grey.button,
body .ui.basic.grey.buttons .button {
	background: transparent none !important;
}
body {
	background-color: #f1f1f1 !important;
	color: var(--dark);
	height: auto !important;
}
body .ui[class*='right labeled'].icon.button {
	padding-right: 3.6em !important;
	padding-left: 1.3em !important;
}

.app-container {
	display: grid;
	grid-template-columns: 210px 1fr;
	min-height: 100vh;
}

.page-content {
	/* display: grid; */
	/* grid-template-columns: 1fr; */
	/* grid-template-rows: 70px 1fr; */
}
.page-padding {
	padding: 30px;
}
.page-margin {
	margin: 30px;
}
.no-padding {
	padding: 0 !important;
}

.table {
	background: #fff;
	width: 100%;
}

.sub-header {
	background: var(--grey-2);
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	padding: 13px 20px;
	margin: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.sub-header h3 {
	font-weight: 400;
}
.sub-header svg {
	padding-right: 10px;
}
.sub-header-txt {
	background: #fff;
	margin-bottom: 20px;
	padding: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.roles-labels .ui.label {
	margin: 3px !important;
	min-width: 105px;
	text-align: left;
	display: inline-block;
}
.coupler {
	text-transform: uppercase;
	background: var(--grey-2);
	padding: 4px;
	margin: 5px;
	display: inline-block;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
}
.label-value-small {
	text-transform: uppercase;
	background: var(--grey-2);
	margin: 5px;
	font-size: 14px;
	display: grid;
	grid-template-columns: 2fr 1fr;
	justify-content: center;
	align-content: center;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
}
.label-value-small .left {
	padding: 4px;
}
.label-value-small .right {
	padding: 4px;
	align-self: stretch;
	background: var(--green);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.company-names-small-block {
	display: block;
	padding: 5px;
	color: var(--dark);
}
.company-names-small-block:hover {
	color: var(--green);
}

.ui.basic.red.label {
	background: #fff !important;
}

.company-logo {
	padding: 0px !important;
}

.ui.segment.wrapper {
	padding: 0 !important;
	border: none;
	box-shadow: 0 5px 15px 0 rgba(36, 36, 39, 0.1);
}
.ui.orange.basic.button.delete:hover {
	background: #d64f1b !important;
	color: #fff !important;
}
.ui.orange.basic.button.delete.left {
	text-align: left;
	padding: 13px 10px;
	border-radius: 5px;
}
.rrt-ok-btn {
	color: #d64f1b;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-ok-btn:active {
	background-color: #d64f1b !important;
	color: white;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-cancel-btn:active {
	background-color: #ccc !important;
	color: #333 !important;
}
.rrt-message:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f071';
	color: #d64f1b;
	padding: 0 0 10px 0;
	font-size: 20px;
	display: block;
}
.rrt-message {
	background: #d6501b1c !important;
}
.recent-activity .content {
	padding: 0 !important;
}
.recent-activity .header {
	padding: 1em;
}
.recent-activity .event {
	border-bottom: 1px solid #f2f2f2;
	padding: 12px 12px 8px 12px !important;
	margin-bottom: 10px !important;
}
.recent-activity .event:last-child {
	border: none;
	margin: 0;
	padding-bottom: 0 !important;
}
.no-content-message:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f071';
	color: #ce7c3a;
	padding: 0 20px 10px 0;
	font-size: 20px;
}
.no-content-message {
	padding: 20px;
	display: block;
}
.address-line-small {
	font-size: 12px;
	padding: 0;
	margin: 0;
	line-height: 14px;
}
.address-line-small.inline {
	display: inline;
}
.roleContainer.inline {
	display: inline;
}
.roleContainer.block {
	display: block;
}
.space-small {
	height: 10px;
}
.inline {
	display: inline-block;
}
.sub-header.inpage {
	border-radius: 5px;
	margin-bottom: 20px;
}
.mobile-spacer.v20 {
	display: none;
}
@media (max-width: 900px) {
	.mobile-spacer.v20 {
		display: block;
		height: 20px;
	}
}
.message-info {
	background: #a5e6fb82 url('/assets/icons/icon-info.svg') no-repeat 10px center;
	display: block;
	border: 1px solid #abd0ea;
	padding: 15px 15px 15px 55px;
	border-radius: 5px;
	margin: 14px;
	color: #227ab7;
	width: 100%;
}
.message-warn {
	background: #fde49e url('/assets/icons/icon-warn.svg') no-repeat 10px center;
	display: block;
	border: 1px solid #ce7c3a;
	padding: 15px 15px 15px 55px;
	border-radius: 5px;
	margin: 0;
	color: #ce7c3a;
	width: 100%;
}
p .icon {
	margin-right: 0.3em !important;
}
.ui.card > .image.production-image {
	background: none;
	width: 90% !important;
	margin: 5%;
	height: auto !important;
}
.ui.form > p.message-info {
	margin-top: 0 !important;
}
.form-label-description {
	font-style: italic;
	font-size: 12px;
	padding: 5px;
	color: rgba(0, 0, 0, 0.5);
}
.interaction-list-more {
	text-align: center;
	color: #bbb;
	margin: 5px 5px 20px 5px;
	padding: 0;
}
.interaction-list-more p {
	margin: 0;
	padding: 0;
}
.icon-wrapper .icon {
	margin-right: 10px !important;
	color: #98a0aa;
}
.icon-wrapper .currency {
	padding-right: 7px;
}
.side-panel-button {
	background: #fff;
	border: 1px solid #a7b4c6;
	color: var(--dark) !important;
	padding: 10px;
	text-align: left;
	display: block;
	border-radius: 5px;
	transition: all 0.2s ease-in-out;
	margin-bottom: 12px;
}
.side-panel-button[aria-current='page'] {
	background: #d2d5da;
	border: 1px solid #7f8c9c;
}
.side-panel-button:hover {
	color: var(--dark);
	border: 1px solid #7f8c9c !important;
}
.ui.buttons .button.in-form-archive {
	margin-top: -37px;
	z-index: 99999;
}
.placeholder-group {
	display: block;
}
.placeholder-line {
	background: var(--placeholder);
	height: 8px;
	margin: 1px 0 !important;
	display: inline-block;
}
.placeholder-line.w100 {
	width: 100%;
}
.placeholder-line.w90 {
	width: 90%;
}
.placeholder-line.w80 {
	width: 80%;
}
.placeholder-line.w70 {
	width: 70%;
}
.placeholder-line.w60 {
	width: 60%;
}
.placeholder-line.w50 {
	width: 50%;
}
.placeholder-line.w40 {
	width: 40%;
}
.placeholder-line.w30 {
	width: 30%;
}
.placeholder-line.w20 {
	width: 20%;
}
.placeholder-line.w10 {
	width: 10%;
}
.pull-right {
	float: right;
}
.scanner-image {
	width: 70%;
	max-width: 300px;
	display: block;
	margin: 50px auto;
}
.text-center {
	text-align: center;
}
.collectKegIds {
	margin: 0 0 15px 0;
	display: flex;
	align-content: space-between;
	width: 100%;
}
.collectKegIds .ui.input {
	width: 60%;
	margin-right: 5%;
}
.collectKegIds .ui.button {
	width: 35%;
}
.collectKegIds .ui.input {
	width: 100% !important;
	margin-right: 0 !important;
}
.keg-fill-column-center {
	border-left: 1px solid #e9eaea;
	border-right: 1px solid #e9eaea;
}
.keg-selector-page .ui.label {
	margin: 5px !important;
}
.grid-padding {
	padding: 10px 10px 10px 20px;
}
.grid-padding.end {
	padding: 10px 20px 10px 10px;
}
.inform-heading {
	background: var(--grey-2);
	padding: 10px;
	text-align: center;
	margin: 0 -13px 10px -13px !important;
}
.inform-heading.start {
	margin-left: 0 !important;
	border-top-left-radius: 5px;
}
.inform-heading.end {
	margin-right: 0 !important;
	border-top-right-radius: 5px;
}
.keg-selector-page .ui.segment:first-child {
	margin: 1rem 0 !important;
}
.keg-selector-page .ui[class*='vertically divided'].grid > .row {
	padding: 0 !important;
}
.illustration {
	width: 400px;
	margin: 30px;
}

.status-dot {
	background: #ccc;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50px;
}
.status-dot.new {
	background: #4a90e2;
	border: 1px solid #3a78c2;
}
.status-dot.in {
	background: #24b47e;
	border: 1px solid #159570;
}
.status-dot.out {
	background: #f5a623;
	border: 1px solid #d4901e;
}
.status-dot.overtime {
	background: #f54823;
	border: 1px solid #d33615;
}
.bump-dot .status-dot {
	margin-right: 10px;
}
.time {
	margin-top: 10px;
}
.time .hrs,
.time .min {
	background: #e1e2e9;
	padding: 5px 8px;
	border-radius: 5px;
	display: inline-block;
	margin: 2px;
	min-width: 30px;
	text-align: center;
}

/* Simple loader */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 30px;
}
.lds-ellipsis div {
	position: absolute;
	top: 10px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: var(--green);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.grey .lds-ellipsis div {
	background: rgba(0, 0, 0, 0.1);
}
.lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(19px, 0);
	}
}

/*  tiny loader */
.tiny-loader,
.tiny-loader:after {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
.tiny-loader {
	margin: 0 auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 5px solid rgba(0, 0, 0, 0.05);
	border-right: 5px solid rgba(0, 0, 0, 0.05);
	border-bottom: 5px solid rgba(0, 0, 0, 0.05);
	border-left: 5px solid rgba(0, 0, 0, 0.1);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.block-center {
	display: block;
	text-align: center;
}

/* ERROR */
.batch-complete-text .bulk-error h2 {
	color: #8c202e;
	text-align: center;
}
/* SUCCESS */
.batch-complete-text .bulk-success h2 {
	color: #24b47e;
	text-align: center;
}
.ui.green.button,
.ui.green.buttons .button {
	background-color: #24b47e !important;
}
.bulk-error,
.bulk-success {
	text-align: center;
}
.batch-complete-text p {
	color: var(--dark);
	margin: 50px;
	text-align: center;
}
.small-label-right {
	width: 48px;
	display: inline-block;
	padding-right: 10px;
	text-align: right;
}
.medium-label-right {
	width: 65px;
	display: inline-block;
	padding-right: 10px;
	text-align: right;
}
.list-of-available-kegs .button {
	margin: 3px !important;
}

/* REPORTS */
.report-nav-drawer {
	background: #b5bcc3;
	margin: 0;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	transition: all 500ms ease;
}
.report-nav-drawer a {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	text-align: center;
	box-sizing: border-box;
	transition: all 500ms ease;
	position: relative;
}
.report-nav-drawer svg {
	margin: 25px;
	color: #2b2c50;
	transition: all 500ms ease;
}
.report-nav-drawer img {
	margin: 24px 25px 23px 25px;
}
.report-nav-drawer h3 {
	font-family: 'Muli', sans-serif;
	font-size: 14px;
	background: #f6f9fc;
	padding: 10px;
	min-height: 55px;
	margin: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	text-transform: uppercase;
	font-weight: 400;
	color: var(--dark);
	letter-spacing: 0.3px;
	text-align: center;
	transition: all 500ms ease;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
}
.report-nav-drawer a[aria-current='page'] {
	background: #24b47e;
}
.report-nav-drawer a[aria-current='page']:after {
	content: '';
	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	position: absolute;
	bottom: -10px;
	left: calc(50% - 14px);
	border-top: 12px solid #159570;
}

.report-nav-drawer a[aria-current='page'] h3 {
	background: #159570;
	color: #fff;
}

.report-nav-drawer a:hover {
	box-shadow: 5px 15px 25px 0 rgba(50, 50, 93, 0.07),
		10px 25px 25px 0 rgba(50, 50, 93, 0.07);

	transform: translateY(-5px);
}

/* KEG LIST */
.keg-list-nav-drawer {
	background: #aab7c4;
	margin: 0;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 20px;
	transition: all 500ms ease;
}
.keg-status-card a {
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	text-align: center;
	box-sizing: border-box;
	transition: all 500ms ease;
	position: relative;
	display: block;
}
.keg-status-card a:hover {
	box-shadow: 5px 15px 25px 0 rgba(50, 50, 93, 0.07),
		10px 25px 25px 0 rgba(50, 50, 93, 0.07);
	transform: translateY(-5px);
}
.keg-list-nav-drawer h3.title {
	font-family: 'Muli', sans-serif;
	font-size: 16px;
	padding: 10px;
	margin: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.3px;
	text-align: center;
	transition: all 500ms ease;
	background: #3b4170;
}
/* Active Arrow */
.keg-list-nav-drawer a[aria-current='page']:after {
	content: '';
	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	position: absolute;
	bottom: -10px;
	left: calc(50% - 14px);
}

/* Active = All */
.keg-list-nav-drawer .all a[aria-current='page']:after {
	border-top: 12px solid #252533;
}
.keg-status-card.all .active .ui.statistic {
	background: #3b4170;
}
.keg-status-card.all .active .ui.statistic .value {
	color: #fff !important;
}
.keg-list-nav-drawer .all h3.title {
	background: #2b2c50;
}

/* Active = New */
.keg-list-nav-drawer .new a[aria-current='page']:after {
	border-top: 12px solid #fbbd08;
}
.keg-status-card.new .active .ui.statistic {
	background: #ffcc31;
}
.keg-list-nav-drawer .new h3.title {
	background: #fbbd08;
	color: rgba(0, 0, 0, 0.7);
}

/* Active = Empty */
.keg-list-nav-drawer .empty a[aria-current='page']:after {
	border-top: 12px solid #9a5427;
}
.keg-status-card.empty .active .ui.statistic {
	background: #a5673f;
}
.keg-status-card.empty .active .ui.statistic .value {
	color: #fff !important;
}
.keg-list-nav-drawer .empty h3.title {
	background: #9a5427;
}

/* Active = Warehouse */
.keg-list-nav-drawer .warehouse a[aria-current='page']:after {
	border-top: 12px solid #b5cc18;
}
.keg-status-card.warehouse .active .ui.statistic {
	background: #c8e027;
}
.keg-list-nav-drawer .warehouse h3.title {
	background: #b5cc18;
	color: rgba(0, 0, 0, 0.7);
}

/* Active = Dispatched */
.keg-list-nav-drawer .dispatched a[aria-current='page']:after {
	border-top: 12px solid #00b5ad;
}
.keg-status-card.dispatched .active .ui.statistic {
	background: #07c9bf;
}
.keg-status-card.dispatched .active .ui.statistic .value {
	color: #fff;
}
.keg-list-nav-drawer .dispatched h3.title {
	background: #00b5ad;
	color: #fff;
}
.sub-drawer-header {
	background: #98a0aa;
	padding: 15px 30px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 400;
	font-family: 'Muli', sans-serif;
}

/* DASHBOARD */
.dashboard-keg-status-drawer {
	background: #aab7c4;
	margin: 0;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	transition: all 500ms ease;
}
.keg-status-card a {
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	text-align: center;
	box-sizing: border-box;
	transition: all 500ms ease;
	position: relative;
}
.keg-status-card.dashboard .description {
	background: #f6f9fc;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.keg-status-card .ui.statistic {
	background: #fff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-family: 'Muli', sans-serif;
	font-size: 40px;
	line-height: 50px;
	padding: 20px;
	display: block;
	margin: 0;
}
.keg-status-card .ui.statistic .value {
	color: var(--dark);
}
.keg-status-card .title {
	background: var(--dark);
	font-family: 'Muli', sans-serif;
	color: #fff;
	display: block;
	padding: 5px;
	font-size: 14px;
	text-transform: uppercase;
}
.keg-status-card.new .title {
	background: #fbbd08;
}
.keg-status-card.empty .title {
	background: #a5673f;
}
.keg-status-card.warehouse .title {
	background: #b5cc18;
}
.keg-status-card.dispatched .title {
	background: #00b5ad;
}
.keg-status-card .description {
	font-family: 'Muli', sans-serif;
	color: var(--dark);
	font-size: 12px;
	line-height: 14px;
	padding: 10px 20px;
	margin: 0;
	display: block;
}
.dashboard .basic.table {
	width: 100% !important;
}
.dashboard .basic.table .label {
	width: 35%;
	font-weight: 700;
}
.dashboard .basic.table .value {
	text-align: center;
}
.dashboard .basic.table .actions {
	text-align: right;
	width: 45%;
}

.side-drawer .main-nav {
	transition: all 0.2s ease-in-out;
}
.mobile-nav-trigger {
	display: none;
}
.side-drawer-header {
	background: rgba(0, 0, 0, 0.15);
}

.keg-selector-page .ui.form .button.big {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 9999;
	opacity: 1 !important;
	width: calc(100% - 210px);
	margin: 0;
	border-radius: 0;
	padding: 20px;
	box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
}
.keg-selector-page .ui.form .button.big.disabled {
	cursor: not-allowed;
	background: #8898aa;
}
.keg-selector-page .ui.form .button.big {
	background: var(--purple1);
}
.keg-selector-page {
	margin-bottom: 70px;
}
.fineprint {
	font-size: 12px;
	font-style: italic;
}
.fineprint span {
	background: #eee;
	padding: 3px 5px;
}
.staging-label {
	background: #555abf;
	color: #fff;
	text-align: center;
	padding: 8px 12px;
	text-transform: uppercase;
}
.hideDesktop {
	display: none;
}

.ui.basic.table.brands-table tbody tr:nth-child(2n) {
	background: var(--grey-1) !important;
}
.positive,
.value.positive {
	font-weight: 700;
}
.zero {
	color: rgba(0, 0, 0, 0.5) !important;
}
.brands-table .label {
	padding-left: 7px !important;
}
.message-row {
	background: #e5f1fe url(/assets/icons/icon-info.svg) no-repeat 10px center;
	border: 1px solid #bccfe4;
	padding: 15px 20px 15px 50px;
	color: #202c74;
}

.profile-image {
	border-radius: 5px;
}
.profile-image.tiny {
	max-width: 40px;
}
.side-drawer .product-of img {
	max-width: 150px;
}

.clock-employees-column {
	border-left: 1px solid #eee;
}
.employee-cards-wrapper {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1600px) {
	.employee-cards-wrapper {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media (max-width: 1280px) {
	.employee-cards-wrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media (max-width: 960px) {
	.employee-cards-wrapper {
		grid-template-columns: 1fr;
	}
}
.employee-card {
	background: #b5bcc377;
	border: 1px solid #b5bcc3;
	border-radius: 5px;
	display: grid;
	grid-template-columns: 40px 1fr 15px;
	grid-gap: 10px;
	align-items: center;
	transform: scale(1);
	transition: all 0.2s cubic-bezier(0.07, 0.87, 0.43, 0.99);
}
.employee-card:hover {
	border: 1px solid #98a0aa;
	cursor: pointer;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
	transform: scale(1.04);
}
.employee-card .img {
	border-right: 1px solid #b5bcc3;
	background: #b5bcc356;
	line-height: 0;
}
.employee-card img {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	max-height: 40px;
}
.employee-card .txt {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	grid-row-gap: 2px;
}
.employee-card .txt .id {
	line-height: 14px;
	font-weight: 700;
	align-self: end;
	color: #46485a;
}
.employee-card .txt .name {
	align-self: start;
	line-height: 14px;
	color: #46485a;
}
.department-label {
	background: #b5bcc377;
	border: 1px solid #b5bcc3;
	padding: 3px 8px;
	display: inline-block;
	margin: 2px;
	font-size: 12px;
	border-radius: 5px;
	transition: all 0.2s ease-in-out;
}
.department-label.active,
.department-label:hover {
	background: #e5f1fe;
	color: #202c74;
	border: 1px solid #bccfe4;
	cursor: pointer;
}

.ui.divider {
	color: #b5bcc3 !important;
}
body .ui.icon.input > input {
	padding-right: 33px !important;
}

@media (max-width: 768px) {
	.clock-page .ui.grid > .column.row > [class*='six wide'].column,
	.clock-page .ui.grid > .row > [class*='six wide'].column,
	.clock-page .ui.grid > [class*='six wide'].column,
	.clock-page .ui.grid > .column.row > [class*='ten wide'].column,
	.clock-page .ui.grid > .row > [class*='ten wide'].column,
	.clock-page .ui.grid > [class*='ten wide'].column {
		width: 100% !important;
	}
}
.search-results-message {
	background: #e5f1fe;
	border: 1px solid #bccfe4;
	padding: 16px 20px;
	color: #202c74;
	border-radius: 5px;
	margin: 0 0 20px 0;
}
.search-results-message span {
	background: #bccfe4;
	border-radius: 3px;
	font-weight: 700;
	padding: 3px 6px;
}
.department-results-message {
	background: #e5f1fe;
	border: 1px solid #bccfe4;
	padding: 16px 20px;
	color: #202c74;
	border-radius: 5px;
	margin: 0 0 20px 0;
}
.department-results-message span {
	background: #bccfe4;
	border-radius: 3px;
	font-weight: 700;
	padding: 3px 6px;
}

.scanEmployeeForm,
.searchEmployeeForm {
	text-align: center;
}

.scorecard {
	border: 1px solid #eee;
	display: inline-block;
	border-radius: 4px;
	min-height: 100px;
}
.clocked-time {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 25px;
}
.clocked-time .scorecard {
	display: grid;
	grid-template-columns: 1fr 100px;
	justify-content: center;
	color: #fff;
	text-transform: uppercase;
	font-size: 23px;
	line-height: 28px;
}
.clocked-time .scorecard .label,
.clocked-time .scorecard .value {
	padding: 24px;
	align-self: center;
	text-align: center;
}
.clocked-time .scorecard .value span {
	padding: 11px 0;
	display: block;
}

.clocked-time .scorecard.clocked-in {
	background: #24b47e;
	border: 1px solid #159570;
}
.clocked-time .scorecard.clocked-in .value {
	background: #159570;
	font-weight: 700;
}

.clocked-time .scorecard.clocked-out {
	background: #f5a623;
	border: 1px solid #d4901e;
}
.clocked-time .scorecard.clocked-out .value {
	background: #d4901e;
	font-weight: 700;
}

.dashboard-clocked-drawer {
	background: #fff;
	padding: 30px;
}

.scorecard-time {
	border: 1px solid #eee;
	display: inline-block;
	border-radius: 4px;
}
.scorecard-wrapper {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 25px;
}
.scorecard-time .label,
.scorecard-time .value {
	padding: 15px;
	text-align: center;
}
.scorecard-time .label {
	font-size: 16px;
	text-transform: uppercase;
	background: #f8f8f8;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 15px;
}
.scorecard-time .value {
	font-size: 20px;
	background: #eee;
	min-height: 62px;
	font-weight: 700;
	align-self: center;
}
.scorecard-time .value span {
	display: block;
	padding: 6px 0;
}
.scorecard-time .label img,
.scorecard-time .label svg {
	width: 28px;
	height: 32px;
	justify-self: right;
}
.scorecard-time .label svg {
	padding: 1px 0;
}
.scorecard-time .label span {
	justify-self: left;
	align-self: center;
}
.quick-links i.icon {
	margin-right: 10px;
}
.sub-drawer-header h3 {
	margin: 0;
}
.sub-drawer-header.with-btn {
	display: grid;
	grid-template-columns: 1fr 220px;
	align-items: center;
}
.download-btn {
	box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	background-color: #24b47e !important;
	color: #fff;
	border-radius: 4px;
	font-weight: 700;
	text-align: center;
	display: grid;
	grid-template-columns: 40px 1fr;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
}
.download-btn .icon {
	background: rgba(0, 0, 0, 0.1);
	width: 100%;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	height: 100%;
}
.download-btn span,
.download-btn .icon {
	padding: 10px;
	display: block;
}
.download-btn:hover {
	color: #fff;
	background-color: #373748 !important;
}

.ReactTable .rt-thead.-header {
	background: #fff;
	color: #333;
	font-weight: 700;
}
.rt-thead.-filters {
	background: #159f7b;
}
.ReactTable .-pagination .-btn {
	color: #fff !important;
	text-transform: uppercase;
	font-weight: 700;
	background: #159f7b !important;
}
.full-page-loader {
	display: grid;
	width: 100%;
	height: 60vh;
	align-items: center;
	justify-items: center;
}

.ReactTable .rt-tbody .rt-td {
	text-align: center;
}
.ReactTable .rt-tbody .rt-td:nth-child(4),
.ReactTable .rt-tbody .rt-td:nth-child(12) {
	text-align: left;
}

@media (max-width: 1300px) {
	.ReactTable .rt-tbody .rt-td:nth-child(1),
	.ReactTable .rt-tbody .rt-td:nth-child(8),
	.ReactTable .rt-tbody .rt-td:nth-child(10),
	.ReactTable .rt-tbody .rt-td:nth-child(11),
	.ReactTable .rt-thead .rt-th:nth-child(1),
	.ReactTable .rt-thead .rt-th:nth-child(8),
	.ReactTable .rt-thead .rt-th:nth-child(10),
	.ReactTable .rt-thead .rt-th:nth-child(11) {
		display: none;
	}
	.ReactTable .rt-tbody,
	.ReactTable .rt-thead,
	.ReactTable .rt-thead.-header {
		min-width: 320px !important;
	}
}
@media (max-width: 920px) {
	.ReactTable .rt-thead .rt-th:nth-child(9),
	.ReactTable .rt-tbody .rt-td:nth-child(9) {
		display: none;
	}
}
@media (max-width: 600px) {
	.ReactTable .rt-thead .rt-th:nth-child(5),
	.ReactTable .rt-tbody .rt-td:nth-child(5),
	.ReactTable .rt-thead .rt-th:nth-child(6),
	.ReactTable .rt-tbody .rt-td:nth-child(6) {
		display: none;
	}
	.sub-drawer-header.with-btn {
		grid-template-columns: 1fr;
	}
	.sub-drawer-header.with-btn h3 {
		display: none;
	}
}
@media (max-width: 400px) {
	.ReactTable .rt-thead .rt-th:nth-child(3),
	.ReactTable .rt-tbody .rt-td:nth-child(3) {
		display: none;
	}
}

/* //////////////////////////////////////////////////////////////////////// */
@media all and (max-width: 900px) {
	.clocked-time,
	.scorecard-wrapper {
		grid-template-columns: 1fr;
	}
	.showMobile {
		display: block;
	}
	.widget h2 {
		text-align: center;
	}
	.app-container {
		grid-template-columns: 1fr;
	}
	.side-drawer .version,
	.side-drawer .product-of {
		display: none;
	}
	.side-drawer .main-nav.closed {
		max-height: 0;
		overflow: hidden;
	}
	.side-drawer .main-nav.open {
		display: block;
		max-height: 1000px;
	}
	.side-drawer .logo {
		text-align: left !important;
		padding: 8px 20px !important;
	}
	.side-drawer .mobile-nav-trigger {
		background: rgba(0, 0, 0, 0.1);
	}
	.side-drawer .mobile-nav-trigger:hover {
		background: var(--dark);
	}
	.side-drawer .mobile-nav-trigger:hover .bars {
		color: #fff !important;
	}
	.side-drawer .mobile-nav-trigger .bars {
		color: #fff;
		margin: 15px 3px;
		font-size: 20px !important;
	}
	.side-drawer-header {
		display: grid;
		grid-template-columns: 1fr 65px;
		align-content: center;
	}
	.side-drawer-header .logo {
		text-align: left;
	}
	.mobile-nav-trigger {
		display: inline-block;
		text-align: right;
		padding: 10px 20px;
	}
	.mobile-nav-trigger:hover {
		cursor: pointer;
	}
	.side-drawer .mobile-nav-trigger:hover .bars {
		color: var(--dark);
	}

	.dashboard-keg-status-drawer {
		grid-template-columns: 1fr;
	}
	.dashboard-keg-status-drawer .keg-status-card .description {
		display: none;
	}
	.dashboard-keg-status-drawer .keg-status-card .title {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.app-container .ui.grid > [class*='three column'].row > .column {
		width: 100% !important;
		margin: 10px 0 !important;
	}
	.app-container .ui.grid > [class*='eleven wide'].column,
	.app-container .ui.grid > [class*='five wide'].column {
		width: 100% !important;
	}
	.ui.two.cards > .card {
		width: 100% !important;
	}
	.app-container .ui.grid > [class*='eight wide'].column {
		width: 100% !important;
	}
	.illustration {
		width: 90%;
		margin: 5%;
	}

	.inform-heading.start {
		margin: -10px 0 10px 0 !important;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.keg-fill-column-center .inform-heading {
		border-radius: 0;
		margin: 10px 0 !important;
	}
	.inform-heading.end {
		margin: 0 !important;
		border-radius: 0;
	}
	.keg-fill-column-center {
		border: none;
	}
	body .ui[class*='right labeled'].icon.button {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	body .ui[class*='right labeled'].icon.button .icon {
		display: none;
	}
	.keg-selector-page {
		padding: 30px 30px 110px 30px;
	}
	.keg-selector-page .ui.form .button.big {
		position: fixed !important;
		width: 100% !important;
		left: 0;
		padding: 25px 0;
		bottom: 0;
		z-index: 99999;
		opacity: 1 !important;
	}
	.keg-list-nav-drawer,
	.report-nav-drawer {
		grid-template-columns: 1fr;
	}
	.ui.table thead {
		display: none !important;
	}
	.ui.table tbody tr td {
		text-align: center;
	}
	.value {
		line-height: 25px;
		padding: 5px;
		font-size: 17px;
	}
	.countDispatched:after {
		content: 'Dispatched';
		font-size: 12px;
		color: #fff;
		background: #00b5ad;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}
	.countWarehouse:after {
		content: 'In Warehouse';
		font-size: 12px;
		color: #fff;
		background: #b5cc18;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}

	.ui.modal > .image.content {
		grid-template-columns: 1fr !important;
	}
	.ui.modal .actions .button {
		display: block;
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.ui.modal .content > .description {
		width: auto !important;
	}
	.ui.modal .content .slider-inner-content h3 {
		font-size: 23px !important;
	}
	.ui.modal .content .slider-inner-content p {
		font-size: 14px !important;
	}
	body .ui.grid > .row > [class*='eleven wide'].column,
	body .ui.grid > .row > [class*='five wide'].column {
		width: 100% !important;
	}
	body .ui.grid > [class*='two column'].row > .column {
		width: 100% !important;
		margin-bottom: 20px;
	}
	body .ui.grid > [class*='two column'].row > .column.fill {
		margin-bottom: 0;
	}
	.dashboard .basic.table .actions {
		text-align: center !important;
	}
	.dashboard .basic.table .value {
		font-size: 20px;
		font-weight: 700;
	}
	.ui.modal .content > .image {
		width: 80%;
		margin: 20px 20%;
	}
	.slider-inner-content {
		margin: 10px 10% 30px 10% !important;
	}
	.logged-in-user-details img.ui.image {
		display: none !important;
	}
	.app-header h1 {
		font-size: 20px !important;
	}
	.side-drawer {
		grid-template-rows: 1fr !important;
	}
	.app-container {
		grid-template-rows: auto 1fr !important;
	}
	.widget h3 {
		text-align: center;
		background: var(--grey-2);
		padding: 10px;
		border-radius: 5px;
	}
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
}
