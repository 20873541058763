.side-drawer {
	background-color: #46485a;
	background-image: linear-gradient(0deg, #252533 0%, #46485a 94%);
}
.side-drawer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 134px 1fr auto auto;
}
.licence-details {
	color: #fff;
	background: rgba(38, 38, 45, 0.4);
	padding: 10px;
}
.licence-details p {
	margin: 0;
	display: grid;
	grid-template-columns: 90px 1fr;
}
.licence-details .left {
	color: rgba(255, 255, 255, 0.3);
}
.licence-details .right {
	color: rgba(255, 255, 255, 0.8);
}
.side-drawer .logo {
	padding: 15px 20px;
	text-align: center;
}
.side-drawer .logo img {
	max-height: 100px;
}

.main-nav ul {
	list-style-type: none;
}

.main-nav ul,
.main-nav li,
.main-nav a {
	margin: 0;
	padding: 0;
}

.main-nav span,
.main-nav a {
	text-decoration: none;
	border-bottom: 1px solid rgba(38, 38, 45, 0.4);
	display: grid;
	grid-template-columns: 25px 1fr 25px;
	font-family: 'Source Sans Pro', sans-serif;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	padding: 15px 10px;
	transition: all 0.2s;
	align-items: center;
}

.main-nav a:hover {
	background: var(--green);
}
.main-nav a:hover svg {
	color: #fff;
}

.product-of h3 {
	background: rgba(0, 0, 0, 0.2);
	padding: 10px;
	margin: 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 400;
}
.product-of a {
	background: rgba(0, 0, 0, 0.08);
	display: block;
	padding: 15px 10px 10px 10px;
	margin: 0;
	text-align: center;
}
.product-of a img {
	max-height: 40px;
}
.version {
	border-top: 2px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	margin: 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 400;
}

@media (max-width: 900px) {
	.licence-details {
		display: none;
	}
	.licence-details p {
		grid-template-columns: 70px 1fr;
	}
	.side-drawer .logo img {
		max-height: 50px;
	}
}
