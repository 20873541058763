footer {
	display: none;
}
@media (max-width: 900px) {
	footer {
		display: block;
		border-top: 1px solid #b5bcc3b6;
		background: #e2e2e2;
	}
	footer .licence-details {
		display: block;
		background: none;
		padding: 10px;
	}
	footer .licence-details p {
		margin: 0;
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(2, 1fr);
	}
	footer .licence-details .left {
		color: rgba(51, 51, 51, 0.5);
	}
	footer .licence-details .right {
		color: rgba(51, 51, 51, 0.925);
	}
	.sub-footer {
		padding: 10px;
		background: #b5bcc3b6;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.sub-footer .powered-by,
	.sub-footer .ver {
		color: rgba(51, 51, 51, 0.5);
	}
	.sub-footer .ver {
		justify-self: end;
	}
}
@media (max-width: 480px) {
	.sub-footer {
		grid-template-columns: 1fr;
		text-align: center;
	}
	.sub-footer .ver {
		justify-self: center;
	}
	footer .licence-details .left {
		justify-self: right;
	}
}
