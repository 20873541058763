.nav-split {
	background: rgba(38, 38, 45, 0.33);
	padding: 10px;
	margin: 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 400;
}

/*  Sub-Menu  */
.sub-menu {
	border-left: 2px solid var(--green);
	max-height: 0;
	overflow: hidden;
	transition: all 0.5s;
	/* transform: rotateX(90deg); */
}
.side-drawer [aria-expanded='true'] ~ ul {
	display: block;
	max-height: 500px;
	transform: rotateX(0);
	background: rgba(51, 51, 51, 0.4);
}
/* Arrow */
.navigation-item .arrow {
	transition: all 0.5s;
	padding: 0;
	color: #fff;
}
.side-drawer [aria-expanded='true'] .arrow {
	transform: rotate(180deg);
	color: #fff;
}
.side-drawer [aria-expanded='true']:hover .arrow {
	color: #fff;
}
.side-drawer [aria-expanded='false'] .arrow {
	transform: rotate(0deg);
}
/* Item */
.navigation-item .active {
	background: var(--green);
	color: #fff;
}
.side-drawer [aria-expanded='true'] {
	color: #fff;
	background: var(--green);
	border-bottom: 1px solid rgba(38, 38, 45, 0.4);
	border-left: 2px solid var(--green);
}
.side-drawer [aria-expanded='true']:hover {
	color: #fff;
}
.navigation-item span:hover {
	cursor: pointer;
	background: var(--green);
}
.navigation-item .active svg,
.side-drawer [aria-expanded='true'] svg {
	color: #fff;
}
.navigation-item .sub-menu {
	background: rgba(25, 25, 53, 0.1);
}
.navigation-item .sub-menu .active,
.navigation-item .sub-menu a:hover {
	background: rgba(25, 25, 53, 0.7);
}
