.login-page-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 1fr;
	align-items: center;
	justify-items: center;
	min-height: 100vh;
	background: #fff url('/assets/images/login-page-bg.jpg');
	background-size: cover;
}
.login-box {
	background: #ffffff;
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07),
		0 15px 35px 0 rgba(0, 0, 0, 0.07);
	border-radius: 5px;
	min-width: 300px;
}
.login-box h2 {
	font-size: 20px;
	font-weight: 400;
	text-transform: uppercase;
	background: #159570;
	color: #ffffff;
	letter-spacing: 1px;
	text-align: center;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 20px;
	margin: 0;
}
.login-logo {
	text-align: center;
	margin-bottom: 60px;
}
.login-logo img {
	max-width: 200px;
}
.login-box .form-elements {
	background: #f6f9fc;
	padding: 0;
	border: 1px solid #d6dce2;
	border-radius: 5px;
	margin: 0 20px;
}
.form-footer {
	padding: 20px;
	text-align: center;
}
.login-page {
	display: grid;
	grid-template-rows: 100px 1fr;
	align-items: center;
	max-width: 300px;
}
.login-box-footer {
	text-align: center;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.7);
	margin-top: 25px;
}
.login-box-footer a {
	color: rgba(255, 255, 255, 0.7);
	text-decoration: none;
}
.login-box-footer a:hover {
	text-decoration: underline;
}
.button-dark-blue {
	background-color: #159570 !important;
	color: #fff !important;
}
.ui.button.button-dark-blue:disabled {
	background-color: var(--dark) !important;
	opacity: 0.8 !important;
}
.loginErrorMessage {
	background: #e5cdd0 url('/assets/icons/icon-error.svg') no-repeat 10px center;
	color: #8c202e;
	margin: 0;
	padding: 10px 10px 10px 55px;
	border-bottom: 1px solid #8c202e56;
}
.login-page .ui.form .field.error input[type='text'],
.login-page .ui.form .field.error input[type='password'] {
	background: #e5cdd0 url('/assets/icons/icon-error-small.svg') no-repeat right
		center !important;
	color: #8c202e !important;
}
.login-page .ui.form .field.error input[type='text']::placeholder,
.login-page .ui.form .field.error input[type='password']::placeholder {
	color: #8c202e !important;
}
.loginSuccessMessage {
	background: #cffbd4 url('/assets/icons/icon-success.svg') no-repeat 10px
		center;
	color: #159570;
	margin: 20px;
	padding: 10px 10px 10px 55px;
	border: 1px solid #159571a6;
	border-radius: 5px;
}

.login-box .form {
	padding: 20px;
}
.login-box .form .field {
	background-color: #f6f9fc;
	border: 1px solid #d6dce2;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-bottom: 20px;
}
.login-box .ui.form input[type='text'],
.login-box .form .field input {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	border: none;
	background-color: #f6f9fc;
	color: var(--purple1) !important;
}
.login-box .form .field:first-child {
	border-bottom: 0;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-bottom: 0 !important;
}
.login-box .form .field:first-child input {
	border-radius: 0;
}
.login-box .form .field:nth-child(2) {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
.login-box .ui.form input[type='text']:focus,
.login-box .form .field input:focus {
	background-color: #f6f9fc;
}
.login-box.single-input .form .field {
	margin-bottom: 20px !important;
	border: 1px solid #d6dce2;
	border-radius: 5px !important;
}
.login-box.single-input .form .field input[type='text'] {
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}
.login-box .form .field label {
	text-transform: uppercase;
	color: #8898aa;
	font-family: 'Muli', sans-serif;
	font-weight: 400;
	font-size: 12px;
	padding: 10px;
	margin: 0;
}
.forgot-pass-link {
	text-decoration: none;
}
.forgot-pass-link:hover {
	text-decoration: underline;
	cursor: pointer;
}
.version.login {
	letter-spacing: 1px;
	color: rgba(255, 255, 255, 0.7);
	border: none;
}
